<!-- =========================================================================================
    File Name: TodoItem.vue
    Description: Single todo item component
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div
    id="todo-app"
    class="border border-solid d-theme-border-grey-light rounded relative overflow-hidden"
  >
    <div class="title">
      <h4>Notifications</h4>
    </div>
    <div
      v-if="unreadNotifications.length>0"
      class="flex d-theme-dark-bg items-center border border-l-0 border-r-0 border-t-0 border-solid d-theme-border-grey-light"
    >
      <VuePerfectScrollbar class="todo-content-scroll-area" :settings="settings" :key="$vs.rtl">
        <transition-group class="todo-list" name="list-enter-up" tag="ul" appear>
          <li
            class="cursor-pointer todo_todo-item"
            v-for="(notification, index) in unreadNotifications"
            :key="notification._id"
            :style="[{transitionDelay: (index * 0.1) + 's'}]"
          >
            <div class="px-4 py-4 list-item-component" @click="redirectTo(notification)">
              <div class="vx-row">
                <div class="vx-col w-full sm:w-5/6 flex sm:items-center sm:flex-row flex-col">
                  <div class="flex items-center">
                    <h6 class="todo-title">{{notification.title}}</h6>
                  </div>
                </div>

                <div class="vx-col w-full sm:w-1/6 ml-auto flex sm:justify-end">
                  <span>{{elapsedTime(notification.createdAt)}}</span>
                </div>
              </div>
              <div class="vx-row">
                <div class="vx-col w-full">
                  <p class="mt-2 truncate">{{notification.content}}</p>
                </div>
              </div>
            </div>
          </li>
        </transition-group>
      </VuePerfectScrollbar>
      
    </div>
    <div class="emptyRecord" v-else>
      <h2>No record found</h2>
    </div>
    <div class="my-5">
              <!-- {{ (paginationData.page * paginationData.limit) - (paginationData.limit - 1) }} - {{ totalNotificationCount - paginationData.page * paginationData.limit > 0 ? paginationData.page * paginationData.limit : totalNotificationCount }} of {{ totalNotificationCount }}</span> -->
      <vs-pagination :total="totalPage" v-model="paginationData.page"></vs-pagination>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapActions } from "vuex";

export default {
  components: {
    VuePerfectScrollbar
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.3
      },
      unreadNotifications: [],
      totalNotificationCount: 0,
      paginationData: {
        page: 1,
        limit: 50
      }
    };
  },
  computed: {
    totalPage: function() {
      return this.totalNotificationCount / this.paginationData.limit >
        parseInt(this.totalNotificationCount / this.paginationData.limit)
        ? parseInt(this.totalNotificationCount / this.paginationData.limit) + 1
        : parseInt(this.totalNotificationCount / this.paginationData.limit);
    }
  },
  mounted() {
    this.getNotificationList();
  },

  methods: {
    ...mapActions('adminNotifications',['getAllNotificationsList']),
    getNotificationList() {
      this.$vs.loading();
      this.getAllNotificationsList(this.paginationData).then(response => {
        this.unreadNotifications=response.data.data.docs;
                this.totalNotificationCount=response.data.data.pagination.total;
        this.$vs.loading.close();
      }).catch(error=>{
      this.$vs.loading.close();})

    },
    elapsedTime(startTime) {
      let x = new Date(startTime);
      let now = new Date();
      var timeDiff = now - x;
      timeDiff /= 1000;

      var seconds = Math.round(timeDiff);
      timeDiff = Math.floor(timeDiff / 60);

      var minutes = Math.round(timeDiff % 60);
      timeDiff = Math.floor(timeDiff / 60);

      var hours = Math.round(timeDiff % 24);
      timeDiff = Math.floor(timeDiff / 24);

      var days = Math.round(timeDiff % 365);
      timeDiff = Math.floor(timeDiff / 365);

      var years = timeDiff;

      if (years > 0) {
        return years + (years > 1 ? " Years " : " Year ") + "ago";
      } else if (days > 0) {
        return days + (days > 1 ? " Days " : " Day ") + "ago";
      } else if (hours > 0) {
        return hours + (hours > 1 ? " Hrs " : " Hour ") + "ago";
      } else if (minutes > 0) {
        return minutes + (minutes > 1 ? " Mins " : " Min ") + "ago";
      } else if (seconds > 0) {
        return seconds + (seconds > 1 ? " sec ago" : "just now");
      }

      return "Just Now";
    },

    redirectTo(data) {
      if (data.metaData) {
        this.$router.replace({ path: data.metaData });
      }
    }
  },
  watch: {
    "paginationData.page": function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.paginationData.page = newVal;
        this.getNotificationList();
      }
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/scss/vuexy/apps/todo.scss";
</style>
